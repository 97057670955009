
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { mapGetters } from 'vuex'
  import { VRow } from 'vuetify/lib/components'

@Component({
  components: {
    VRow,
    Mobile: () => import('@/components/forms/view/MobileForm.vue'),
    Desktop: () => import('@/components/forms/view/DesktopForm.vue'),
  },
  computed: {
    ...mapGetters('app', ['isResize']),
  },
})
  export default class GenericView extends Vue {
  @Prop({ type: Number }) uid?: number
  @Prop({ type: Number }) parentId?: number
  @Prop({ type: String }) parentModel?: string
  @Prop({ type: Boolean, default: false }) dryRun!: boolean

  // --------------------------------------------------------------
  isResize!: number
  private containerHeight = 0
  private containerWidth = 0

  $refs!: {
    container: HTMLDivElement
  }

  // ---------------------------------------------------------------
  mounted () {
    // TODO: Calculate historic, detail to send
    const { $refs: { container } } = this

    this.containerHeight = container ? container.clientHeight - 60 : 0
    this.containerWidth = container ? container.clientWidth : 0
  }

  get isDesktop () {
    const { $vuetify: { breakpoint: { width } } } = this

    return width >= 875
  }

  @Watch('isResize', { immediate: true })
  resizeComponent () {
    const { $refs: { container }, $vuetify: { breakpoint: { height } }, containerHeight } = this

    if (height < containerHeight) {
      this.containerHeight = container?.clientHeight < height ? container.clientHeight : height
    }

    this.containerWidth = container ? container.clientWidth : 0
  }

  get bind () {
    const { containerWidth, containerHeight, uid, dryRun, parentId, parentModel } = this

    return {
      uid,
      parentId,
      parentModel,
      dryRun,
      containerWidth,
      containerHeight,
    }
  }

  render (h) {
    const { bind, isDesktop } = this
    return h(isDesktop ? 'desktop' : 'mobile', { props: bind })
  }
  }
